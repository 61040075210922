<template>
  <div class="layout-item item-sticky">
    <div class="layout-item--title">Рассказы по эмоциональному интеллекту</div>
    <div
      class="layout-item---posts"
      :class="{ full: showAllEpisodes }"
      ref="postsLayout"
      v-if="blogsList.length"
    >
      <router-link
        v-if="lastEpisode.image"
        :to="`/register/stati/${lastEpisode.id}`"
        class="post-xs display--flex"
      >
        <div class="post-thumbnail">
          <img :src="`${lastEpisode.small_image}`" alt="" />
        </div>
        <div class="post-meta">
          <div class="post-title">{{ lastEpisode.title }}</div>
          <div v-if="progress && lastEpisode && blogChapterProgress(lastEpisode.id)" class="post-progress">
            <svg x="0px" y="0px" viewBox="0 0 14 14" fill="currentColor" :class="{'done' : blogChapterProgress(lastEpisode.id).done}">
              <path
                d="M7,0C3.1,0,0,3.1,0,7s3.1,7,7,7s7-3.1,7-7S10.9,0,7,0z M6,11.2L2.6,7.9l0.7-0.7L6,9.8l5.1-5.6l0.7,0.7L6,11.2z"
              />
            </svg>
            <progress max="100" :value="blogChapterProgress(lastEpisode.id).value"></progress>
          </div>
          <div class="post-tags display--flex">
            <span class="tag-accent">New</span>
          </div>
        </div>
      </router-link>
      <router-link
        v-for="blog in blogsList"
        :key="blog.id"
        :to="`/register/stati/${blog.id}`"
        class="post-xs display--flex"
      >
        <div class="post-thumbnail">
          <img :src="`${blog.small_image}`" alt="" />
        </div>
        <div class="post-meta">
          <div class="post-title">{{ blog.title }}</div>
          <div v-if="progress && blog && blogChapterProgress(blog.id)" class="post-progress" >
            <svg x="0px" y="0px" viewBox="0 0 14 14" fill="currentColor" :class="{'done' : blogChapterProgress(blog.id).done}">
              <path
                d="M7,0C3.1,0,0,3.1,0,7s3.1,7,7,7s7-3.1,7-7S10.9,0,7,0z M6,11.2L2.6,7.9l0.7-0.7L6,9.8l5.1-5.6l0.7,0.7L6,11.2z"
              />
            </svg>
            <progress max="100" :value="blogChapterProgress(blog.id).value"></progress>
          </div>
        </div>
      </router-link>
    </div>
    <div class="btn-action display--flex">
      <button
        v-if="!showAllEpisodes"
        @click="showEpisodes"
        type="button"
        class="btn btn-o btn-sm hidden-xs"
      >
        Смотреть все эпизоды
      </button>
      <button
        v-if="showAllEpisodes"
        @click="hideAllEpisodes"
        type="button"
        class="btn btn-o btn-sm"
      >
        Скрыть
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { computed, onBeforeMount } from "@vue/runtime-core";
import { useStore } from "vuex";
export default {
  name: "story-list-block",
  props: {
    progress: {
      type: Boolean,
      default: false,
    },
    lastEpisodeId: Number,
  },
  inject: ["backUrl"],
  setup() {
    const store = useStore();
    let showAllEpisodes = ref(false),
      postsLayout = ref(null);

    let lastEpisode = computed(() => store.state.blog.lastEpisode),
      blogsList = computed(() => store.state.blog.blogsList), blogProgress = computed(() => store.state.blog.blogProgress);
    
    let blogChapterProgress = computed(() => (id) => {
      let check =  blogProgress.value.find(chapter => chapter.id == id)
      if(check){
        return check
      }
      else{
        return 0
      }
    })

    let hideAllEpisodes = () => {
      postsLayout.value.scrollTop = 0;
      showAllEpisodes.value = false;
    };
    let showEpisodes = () => {
      if (blogsList.value.length < 6) {
        store.dispatch("blog/getAllBlogsList").then(() => {
          showAllEpisodes.value = true;
        });
      } else {
        showAllEpisodes.value = true;
      }
    };

    onBeforeMount(() => {
      if (blogsList.value.length == 0) {
        store.dispatch("blog/getSmallBlogsList");
      }
    });

    return {
      showAllEpisodes,
      hideAllEpisodes,
      postsLayout,
      lastEpisode,
      blogsList,
      showEpisodes,
      blogProgress,
      blogChapterProgress
    };
  },
};
</script>

<style scoped>
.item-sticky {
  position: sticky;
  top: 90px;
}

.post-xs {
  justify-content: flex-start;
  margin: 0 0 20px 0;
}

.post-xs .post-thumbnail {
  margin: 0 15px 0 0;
  height: 74.44px;
  flex: 0 0 74.44px;
  overflow: hidden;
}

.post-thumbnail {
  display: block;
}

.post-thumbnail img {
  display: block;
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 74.44px;
  height: 74.44px;
}

.post-xs .post-title {
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}

.post-title {
  color: var(--color);
  text-decoration: none;
}

.post-meta {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
}

.post-tags {
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 8px 0 0 0;
}

.post-tags span {
  display: inline-block;
  margin: 8px 8px 0 0;
  padding: 3px 7px 4px;
  color: var(--color);
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  box-shadow: none;
  font-size: 13px;
  font-weight: 300;
  line-height: 1em;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
}

.post-tags span.tag-accent {
  color: #ffffff;
  background-color: var(--accent);
  border-color: var(--accent);
}

a.post-thumbnail:hover {
  opacity: 0.7;
}

.post-progress {
  position: relative;
  display: block;
  height: 4px;
  color: var(--color-light);
}

.post-progress .done {
  color: var(--accent);
}

.post-progress progress[value] {
  display: block;
  width: 100%;
  height: 4px;
  border: none;
  appearance: none;
}

.post-progress progress[value]::-webkit-progress-bar {
  background-color: var(--color-light);
  border-radius: 2px;
  box-shadow: none;
}

.post-progress progress[value]::-webkit-progress-value {
  position: relative;
  background-color: var(--accent);
  border-radius: 2px;
}

.post-progress progress[value]::-moz-progress-bar {
  position: relative;
  background-color: var(--accent);
  border-radius: 2px;
}

.post-progress svg {
  position: absolute;
  top: -22px;
  right: 0;
  width: 14px;
  height: 14px;
}

.layout-item---posts {
  max-height: 470px;
  overflow: hidden;
  transition: all 0.3s ease-in;
  margin-bottom: 25px;
}

.layout-item---posts.full {
  max-height: 625px;
  overflow: auto;
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .layout-item---posts .post-xs {
    flex: 0 0 230px;
  }

  .layout-item---posts {
    overflow-x: auto;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
</style>